@charset "utf-8";
/**
   _____                 _     _        
  / ____|               | |   | |       
 | |        __ _   ___  | |_  | |   ___ 
 | |       / _` | / __| | __| | |  / _ \
 | |____  | (_| | \__ \ | |_  | | |  __/
  \_____|  \__,_| |___/  \__| |_|  \___|
 ----------------------------------------
 * Castle's Stylesheet
 * Last Update: 2022/04/22 [0.9.7]
 * Author: ohmyga (https://ohmyga.cn)
 * GitHub: https://github.com/bakaomg/castle-Typecho-Theme/
 * LICENSE: GPL V3 (https://www.gnu.org/licenses/gpl-3.0.html)
 **/

/* ========== PJAX Class ========== */
body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
  display: -ms-flexbox;
  display: -webkit-box;
  flex-direction: column;
  -ms-flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

#moe-pjax-content {
  flex: 1;
  -ms-flex: 1;
  position: relative;
  -webkit-box-flex: 1;
  transition: all 0.3s;
  padding: 64px 0px 0px 0px;
}

/* ========== 动画 ========== */
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* ===== 从下到上淡入 ===== */
@keyframes bottom-top-fadein {
  0% {
    opacity: 0;
    transform: translateY(5vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes bottom-top-fadein {
  0% {
    opacity: 0;
    transform: translateY(5vh);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.moe-animation-bottom-top-fadein {
  animation: bottom-top-fadein;
  animation-duration: 0.3s;
  -webkit-animation: bottom-top-fadein 0.3s;
}

/* ===== 从左到右淡入 ===== */
@keyframes fadein {
  0% {
    opacity: 0;
    transform: translateX(5vh);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    transform: translateX(5vh);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.moe-animation-fadein {
  animation: fadein;
  animation-duration: 0.3s;
  -webkit-animation: fadein 0.3s;
}

/* ===== 淡出 ===== */
@keyframes fadeout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10vh);
  }
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(10vh);
  }
}

/* ===== 旋转 ===== */
@keyframes rotate360deg {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

@-webkit-keyframes rotate360deg {
  0% {
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}

.moe-rotate360deg-infinite {
  animation: rotate360deg 1s infinite linear;
  -webkit-animation: rotate360deg 1s infinite linear;
}

/* ========== 夜间模式 ========== */
/* ===== 背景 =====*/
.mdui-theme-layout-dark #moe-bg:after {
  background-color: rgba(0, 0, 0, 0.55);
}

/* ===== Sidebar ===== */
.mdui-theme-layout-dark #sidebar {
  background-color: #424242;
}

.mdui-theme-layout-dark #sidebar .moe-sidebar-toolbar .mdui-color-grey-100 {
  color: #fff !important;
  background-color: #616161 !important;
}

.mdui-theme-layout-dark #sidebar .moe-sidebar-ul-count {
  color: #000;
  background: #fff;
}

.mdui-theme-layout-dark #sidebar .moe-sidebar-TotalCount {
  color: #000;
  background: #fff;
}

/* ===== Toc Sidebar ===== */
.mdui-theme-layout-dark #toc-sidebar {
  background-color: #424242;
}

.mdui-theme-layout-dark #toc-sidebar .moe-toc-main .toc-link:before {
  background-color: #616161;
}

.mdui-theme-layout-dark #toc-sidebar .moe-toc-main .is-active-link:after,
.mdui-theme-layout-dark #toc-sidebar .moe-toc-main a.toc-link:hover::after {
  background-color: rgba(255, 255, 255, 0.1);
}

/* ===== Search ===== */
.mdui-theme-layout-dark #search-dialog {
  background-color: rgba(66, 66, 66, 0.9);
}

.mdui-theme-layout-dark #search-dialog .moe-search-content form i,
.mdui-theme-layout-dark #search-dialog .moe-search-content form #searchInput {
  color: #fff;
}

.mdui-theme-layout-dark
  #search-dialog
  .moe-search-content
  form
  #searchInput::-ms-input-placeholder {
  color: #fff;
}

.mdui-theme-layout-dark
  #search-dialog
  .moe-search-content
  form
  #searchInput::-webkit-input-placeholder {
  color: #fff;
}

/* ===== 首页文章/归档 =====*/
.mdui-theme-layout-dark #moe-post-list .moe-nopic-card .moe-nopic-excerpt,
.mdui-theme-layout-dark #moe-post-list .moe-nopic-card .moe-left-icon i,
.mdui-theme-layout-dark #moe-post-list .moe-nopic-card .moe-nopic-info {
  color: #fff;
}

.mdui-theme-layout-dark #moe-post-list .moe-nopic-card .moe-nopic-title a {
  color: #f5f5f5;
}

.mdui-theme-layout-dark
  #moe-post-list
  .moe-nopic-card
  .moe-nopic-title
  a:hover {
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
}

/* ===== 文章列表卡片/文章独立页 ===== */
.mdui-theme-layout-dark .moe-post-card .mdui-card-media:after,
.mdui-theme-layout-dark
  #moe-post-list
  .moe-default-card
  .mdui-card-media:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 100;
  position: absolute;
  background: rgba(0, 0, 0, 0.25);
}

.mdui-theme-layout-dark
  .moe-post-card
  .mdui-card-media
  .mdui-card-media-covered,
.mdui-theme-layout-dark
  #moe-post-list
  .moe-default-card
  .mdui-card-media
  .mdui-card-media-covered {
  z-index: 110;
  background: rgba(0, 0, 0, 0.45);
}

.mdui-theme-layout-dark
  #moe-post-list
  .moe-default-card
  .mdui-card-actions
  .moe-card-excerpt {
  color: rgba(255, 255, 255, 0.89);
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content .moe-post-card-copy {
  background: rgba(255, 255, 255, 0.1);
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content hr {
  border-color: rgba(255, 255, 255, 0.12);
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content code:not(.hljs) {
  color: #ffcdd2;
  background-color: #424242;
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content mark {
  background: #aaa;
  border-bottom-color: #bbb;
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content kbd {
  background: #424242;
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content blockquote {
  border-left-color: rgba(255, 255, 255, 0.12);
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content blockquote footer {
  color: rgba(255, 255, 255, 0.7);
}

.mdui-theme-layout-dark .moe-post-card .moe-card-content h1 small,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h2 small,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h3 small,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h4 small,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h5 small,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h6 small {
  color: rgba(255, 255, 255, 0.7);
}

/* 分割线 */
.mdui-theme-layout-dark .moe-post-card .moe-card-content h1:after,
.mdui-theme-layout-dark .moe-post-card .moe-card-content h2:after {
  border-bottom: 1px solid #e0e0e0;
}

/* ===== 分割线 =====  */
@media (max-width: 600px) {
  .mdui-theme-layout-dark .moe-post-card,
  .mdui-theme-layout-dark .moe-comment-card {
    border-bottom: solid 1px rgba(255, 255, 255, 0.3);
  }
}

/* ===== 短代码 ===== */
.mdui-theme-layout-dark .moe-short-code-hidden {
  color: #fff;
  background-color: #fff;
}

.mdui-theme-layout-dark
  .moe-comments-list-card
  .moe-comments-list-header:after {
  background-color: rgba(255, 255, 255, 0.15);
}

/* ===== 评论 ===== */
.mdui-theme-layout-dark .moe-comment-card .moe-comment-card-header:after {
  background-color: rgba(255, 255, 255, 0.15);
}

.mdui-theme-layout-dark
  .moe-comment-card
  .mdui-card-header
  .mdui-card-menu
  .mdui-btn
  i {
  color: #fff !important;
}

.mdui-theme-layout-dark
  .moe-comment-card
  .moe-comment-card-footer
  .moe-comment-card-btns
  .mdui-color-grey-800 {
  background-color: #1e88e5 !important;
}

.mdui-theme-layout-dark
  .moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-time {
  color: rgba(255, 255, 255, 0.79);
}

.mdui-theme-layout-dark
  .moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-textBox {
  color: #fff;
}

.mdui-theme-layout-dark .moe-comments-list-box .moe-comments-box:after {
  background-color: rgba(255, 255, 255, 0.15);
}

/* ===== 评论分页 ===== */
.mdui-theme-layout-dark
  .moe-comments-list-footer
  .moe-comments-page-navigator
  li
  a {
  color: #fff;
}

.mdui-theme-layout-dark
  .moe-comments-list-footer
  .moe-comments-page-navigator
  li
  a:hover {
  color: #000;
  background-color: rgba(255, 255, 255, 0.7);
}

.mdui-theme-layout-dark
  .moe-comments-list-footer
  .moe-comments-page-navigator
  .current
  a {
  color: #000;
  background-color: #e0e0e0;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.55);
}

.mdui-theme-layout-dark
  .moe-comments-list-footer
  .moe-comments-page-navigator
  .current
  a:hover {
  background-color: #e0e0e0;
}

/* ===== 文章导航栏 ===== */
.mdui-theme-layout-dark .moe-post-nav {
  background: rgba(66, 66, 66, 0.5);
}

/* ===== 表情按钮以及表情框 ===== */
.mdui-theme-layout-dark #owoBox {
  background-color: #424242;
}

.mdui-theme-layout-dark #owoBox main.moe-owo-main {
  color: rgba(255, 255, 255, 0.85);
}

.mdui-theme-layout-dark
  .moe-comment-card
  .moe-comment-card-content
  .moe-comment-owo-btn {
  color: rgba(255, 255, 255, 0.85);
}

.mdui-theme-layout-dark #moe-owo-overlay {
  background-color: rgba(0, 0, 0, 0.55);
}

/* ===== 归档页面 ===== */
.mdui-theme-layout-dark
  .moe-archive-tags-card
  .moe-archive-tags-content
  a.moe-archive-tags-link,
.mdui-theme-layout-dark
  .moe-archives-list-card
  .moe-archives-list-content
  .moe-archives-list-alist {
  color: rgba(255, 255, 255, 0.85);
}

.mdui-theme-layout-dark
  .moe-archives-list-card
  .moe-archives-list-content
  .moe-archives-list-alist
  span {
  color: rgba(255, 255, 255, 0.55);
}

.mdui-theme-layout-dark
  .moe-archive-tags-card
  .moe-archive-tags-content
  a.moe-archive-tags-link:hover {
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.3);
}

/* ===== 友链申请框 ===== */
.mdui-theme-layout-dark #links-dialog .mdui-card {
  border-bottom: solid 1px rgba(255, 255, 255, 0.3);
}

/* ========== 背景 ========== */
#moe-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
  position: fixed;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 50% 50%;
}

#moe-bg:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  content: "";
  position: fixed;
  background-color: rgba(0, 0, 0, 0.1);
}

/* ========== Header ========== */
#header {
  transition: all 0.2s;
}

/* AppBar */
.moe-appbar {
  box-shadow: none;
}

.moe-appbar a,
.moe-appbar i {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
}

#header .moe-device-btn-hidden {
  transform: scale(0);
}

#header #decice-toolbar-list {
  width: 180px;
  height: 180px;
  padding: unset;
  overflow: unset;
  overflow-y: unset;
  border-radius: 3px;
  user-select: none;
}

#header #decice-toolbar-list,
#header #decice-toolbar-list li {
  display: flex;
  justify-content: center;
  align-items: center;
}

#header #decice-toolbar-list img {
  width: auto;
  height: auto;
  border-radius: 3px;
}

/* ========== Sidebar ========== */
#sidebar {
  background-color: #fff;
}

#sidebar .moe-sidebar-header {
  width: 100%;
  height: 160px;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#sidebar .moe-sidebar-header .moe-sidebar-header-headimg {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform: translate(20px, 20px);
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.55);
}

#sidebar .moe-sidebar-header .moe-sidebar-header-headimg:hover {
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.55);
  transform: translate(20px, 20px) rotate(20deg) scale(1.1);
}

#sidebar .moe-sidebar-header .moe-sidebar-header-siteInfo {
  color: #fff;
  padding: 0 15px 0 20px;
  transform: translateY(45px);
  text-shadow: 1px 1px 0 #424242;
}

#sidebar
  .moe-sidebar-header
  .moe-sidebar-header-siteInfo
  .moe-sidebar-header-authorName {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

#sidebar
  .moe-sidebar-header
  .moe-sidebar-header-siteInfo
  .moe-sidebar-header-description {
  font-size: 13px;
  font-weight: 400;
}

#sidebar
  .moe-sidebar-header
  .moe-sidebar-header-siteInfo
  .moe-sidebar-header-authorName,
#sidebar
  .moe-sidebar-header
  .moe-sidebar-header-siteInfo
  .moe-sidebar-header-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

#sidebar .moe-sidebar-header .moe-sidebar-header-siteInfo:after {
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  content: "";
  z-index: -1;
  height: 73px;
  position: absolute;
  transform: translateY(20px);
  background-color: rgba(0, 0, 0, 0.2);
}

#sidebar .mdui-list {
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

#sidebar .mdui-list .mdui-list {
  overflow-y: hidden;
}

#sidebar .moe-sidebar-toolbar-has {
  height: calc(100% - 230px);
}

#sidebar .moe-sidebar-ul-count {
  right: 18px;
  color: #fff;
  bottom: 12px;
  font-size: 10px;
  padding-top: 4px;
  padding-left: 7px;
  text-align: center;
  border-radius: 4px;
  padding-right: 7px;
  position: absolute;
  padding-bottom: 2px;
  background: #424242;
}

#sidebar .moe-sidebar-TotalCount {
  color: #fff;
  display: block;
  font-size: 15px;
  padding-top: 3px;
  text-align: center;
  border-radius: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
  background: #424242;
}

#sidebar .moe-sidebar-toolbar {
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  position: absolute;
  padding: 10px 0 10px 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
}

#sidebar .moe-sidebar-toolbar #setting-btn,
#sidebar .moe-sidebar-toolbar #login-btn {
  margin-right: 10px;
}

#sidebar .moe-sidebar-toolbar button:hover {
  transform: rotate(30deg);
}

#sidebar .moe-sidebar-toolbar .moe-night-btn-rotate360:hover {
  transform: rotate(330deg);
}

#sidebar .moe-sidebar-toolbar .moe-night-btn-rotate360 {
  transform: rotate(360deg);
}

/* ========== Toc Sidebar ========== */
#toc-sidebar {
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1100;
  position: fixed;
  width: calc(100% - 56px);
  max-width: 280px;
  transition: all 0.3s;
  background-color: #fff;
  transform: translateX(290px);
}

#toc-sidebar.toc-sidebar-open {
  transform: translateX(0);
}

#toc-sidebar header.moe-toc-header {
  padding: 20px 15px;
}

#toc-sidebar .moe-toc-header .moe-toc-header-title {
  color: inherit;
  font-size: 23px;
  text-align: center;
}

#toc-sidebar main.moe-toc-main {
  overflow: auto;
  position: relative;
  padding-left: 10px;
  max-height: calc(100vh - 66px);
  -webkit-overflow-scrolling: touch;
}

#toc-sidebar main.moe-toc-main .toc-list {
  margin: 0px;
  line-height: 2;
  padding-left: 0px;
}

#toc-sidebar .moe-toc-main ol,
#toc-sidebar .moe-toc-main li {
  list-style: none;
}

#toc-sidebar main.moe-toc-main .toc-list .toc-list {
  padding-left: 10px;
}

#toc-sidebar main.moe-toc-main .is-collapsible {
  overflow: hidden;
  max-height: 1000px;
  transition: all 300ms ease-in-out;
}

#toc-sidebar main.moe-toc-main .is-collapsed {
  max-height: 0;
}

#toc-sidebar main.moe-toc-main .is-position-fixed {
  top: 0;
  position: fixed !important;
}

#toc-sidebar main.moe-toc-main .moe-toc-empty {
  color: inherit;
  font-size: 23px;
  margin-top: 15px;
  text-align: center;
}

/* ===== 目录树链接 ===== */
#toc-sidebar .moe-toc-main a.toc-link {
  height: 100%;
  display: block;
  font-size: 20px;
  color: inherit;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: none;
}

#toc-sidebar .moe-toc-main .toc-link::before {
  left: 0;
  content: "";
  width: 2px;
  height: 40px;
  margin-top: -1px;
  position: absolute;
  display: inline-block;
  background-color: #eee;
}

#toc-sidebar .moe-toc-main .is-active-link {
  font-weight: bolder;
}

#toc-sidebar .moe-toc-main .is-active-link:after,
#toc-sidebar .moe-toc-main a.toc-link:hover::after {
  left: 0;
  right: 0;
  content: "";
  width: 100%;
  height: 40px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.05);
}

/* ===== 打开目录树 改变底部按钮 ===== */
#toc-Btn {
  bottom: 96px;
  z-index: 990;
}

#moe-toc-sidebar-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1090;
  position: fixed;
  transition: all 0.3s;
  background-color: rgba(0, 0, 0, 0.55);
}

#moe-toc-sidebar-overlay.moe-toc-sidebar-overlay-open {
  opacity: 1;
}

/* ========== 登陆框 ========== */
.moe-login {
  width: 480px;
  height: 470px;
  max-width: 480px;
}

.moe-login .mdui-dialog-content {
  padding: 0px;
}

.moe-login .moe-login-header {
  height: 190px;
  z-index: 6010;
  position: relative;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.moe-login .moe-login-header:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: 6020;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
}

.moe-login .moe-login-header a {
  color: #fff;
  float: right;
  transform: translate(-10px, 10px);
}

.moe-login .moe-login-header a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.moe-login .moe-login-header a:active {
  background-color: rgba(255, 255, 255, 0.2);
}

.moe-login .moe-login-header a,
.moe-login .moe-login-header .moe-login-header-title {
  z-index: 6030;
}

.moe-login .moe-login-header .moe-login-header-title {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  font-size: 22px;
  position: absolute;
  color: rgba(255, 255, 255, 0.8);
  background-color: rgba(0, 0, 0, 0.25);
}

.moe-login .moe-login-content {
  padding: 0px 20px 25px 20px;
}

.moe-login .moe-login-content .mdui-checkbox {
  left: 20px;
  bottom: 20px;
  position: absolute;
}

.moe-login .moe-login-content .moe-login-submit {
  right: 20px;
  bottom: 20px;
  position: absolute;
}

.moe-login .moe-login-content .moe-login-submit .moe-login-loadBtn {
  opacity: 0 !important;
  transition: all 0.15s;
  animation: rotate360 1s linear infinite;
  -webkit-animation: rotate360 1s linear infinite;
}

.moe-login .moe-login-content .moe-login-submit .moe-login-loadBtn-display {
  opacity: 1 !important;
}

/* ========== Search ========== */
#search-dialog {
  width: 100%;
  height: 100vh !important;
  max-width: 100% !important;
  max-height: 100vh !important;
  background-color: rgba(255, 255, 255, 0.9);
}

#search-dialog .moe-search-header {
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: 20px 20px 0 20px;
}

#search-dialog .moe-search-header a {
  width: 60px;
  height: 60px;
  float: right;
}

#search-dialog .moe-search-header a i {
  top: 52%;
  left: 37%;
  font-size: 40px;
}

#search-dialog .moe-search-content {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  transition: all 0.3s;
  justify-content: center;
  flex-flow: column nowrap;
}

#search-dialog .moe-search-content form {
  width: 600px;
  margin: auto;
  position: relative;
}

#search-dialog .moe-search-content form i {
  top: 12px;
  left: 20px;
  font-size: 30px;
  position: absolute;
  color: rgba(0, 0, 0, 0.52);
}

#search-dialog .moe-search-content input {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

#search-dialog .moe-search-content form #searchInput {
  outline: 0;
  width: 100%;
  border: none;
  font-size: 22px;
  border-radius: 50px;
  transition: all 0.3s;
  padding: 13px 24px 13px 64px;
  box-shadow: 0 0 4px 0 #939090;
  background: rgba(255, 255, 255, 0.25);
}

#search-dialog .moe-search-content form #searchInput::-ms-input-placeholder {
  text-align: center;
}

#search-dialog
  .moe-search-content
  form
  #searchInput::-webkit-input-placeholder {
  text-align: center;
}

#search-dialog .moe-search-content.moe-search-content-focus form #searchInput {
  border-radius: 5px;
}

#search-dialog .moe-search-content .moe-search-result {
  position: relative;
  display: flex;
  width: 600px;
  margin: 0 auto;
  flex-flow: column nowrap;
  margin-top: 10px;
}

#search-dialog .moe-search-content .moe-search-result .mdui-list {
  padding: 0;
}

#search-dialog .moe-search-content .moe-search-result .search-result-none {
  padding: 20px;
  font-size: 23px;
}

/* ========== 首页/归档页面卡片 ========== */
/* 默认样式 */
#moe-post-list {
  margin-top: 20px;
}

#moe-post-list .mdui-card {
  width: 900px;
  margin: 18px auto 0px auto;
}

#moe-post-list .moe-archive-top-card main {
  padding: 15px;
  font-size: 22px;
  text-align: center;
}

#moe-post-list .moe-archive-top-card main i {
  padding: 0 10px;
  font-style: normal;
  font-weight: bolder;
}

#moe-post-list .moe-default-card .mdui-card-media {
  overflow: hidden;
}

#moe-post-list .moe-default-card .mdui-card-media .moe-card-cover-image {
  height: 300px;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

#moe-post-list .moe-default-card .mdui-card-media .mdui-card-primary-title {
  color: #fff;
  text-decoration: none;
}

#moe-post-list .moe-default-card .mdui-card-actions .moe-card-excerpt {
  padding: 8px;
  font-size: 15px;
  text-indent: 3px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.65);
}

/* ===== 无图 ===== */
#moe-post-list .moe-nopic-card {
  padding: 30px;
}

#moe-post-list .moe-nopic-card .moe-left-icon {
  float: left;
  width: 42px;
  height: 42px;
  cursor: default;
  border-radius: 50%;
  margin: 0px 25px 10px 0px;
  border: 1px solid #bdbdbd;
}

#moe-post-list .moe-nopic-card .moe-left-icon i {
  height: 45px;
  display: flex;
  color: #757575;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
}

#moe-post-list .moe-nopic-card .moe-nopic-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 0 !important;
}

#moe-post-list .moe-nopic-card .moe-nopic-title a {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
  transition: all 0.29s ease;
}

#moe-post-list .moe-nopic-card .moe-nopic-title a:hover {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.35);
}

#moe-post-list .moe-nopic-card .moe-nopic-excerpt {
  margin: 0 0 10px;
  line-height: 1.5em;
  margin-block-end: 1em;
  margin-inline-end: 0;
  margin-block-start: 1em;
  margin-inline-start: 0;
  color: rgba(0, 0, 0, 0.65);
}

#moe-post-list .moe-nopic-card .moe-nopic-info {
  font-size: 15px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.68);
  margin: 10px 0px -18px 0px;
}

#moe-post-list .moe-nopic-card .moe-nopic-info .mdui-icon {
  font-size: 16px;
}

#moe-post-list .moe-nopic-card .moe-nopic-info span {
  font-size: 14px;
  margin-right: 10px;
}

#moe-post-list .moe-nopic-card .moe-nopic-info .moe-author-icon,
#moe-post-list .moe-nopic-card .moe-nopic-info .moe-time-icon {
  font-weight: bolder;
}

/* ===== 分页 ===== */
#moe-post-list .moe-pagination {
  margin: auto;
  width: 900px;
  text-align: center;
  padding: 30px 0 35px 0;
}

#moe-post-list .moe-pagination .moe-prev {
  float: left;
}

#moe-post-list .moe-pagination .moe-page-number {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.55);
}

#moe-post-list .moe-pagination .moe-next {
  float: right;
}

#moe-post-list .moe-pagination .moe-prev:disabled i,
#moe-post-list .moe-pagination .moe-next:disabled i {
  color: rgba(255, 255, 255, 0.55) !important;
}

#moe-post-list .moe-pagination .moe-prev:disabled,
#moe-post-list .moe-pagination .moe-next:disabled {
  cursor: not-allowed !important;
}

/* ========== 文章/独立页面 ========== */
/* 虽然上面写过了 但为了方便区分还是再写一遍 */
.moe-post-card {
  width: 900px;
  margin: 38px auto 20px auto;
}

.moe-post-card .mdui-card-media {
  overflow: hidden;
}

.moe-post-card .mdui-card-media .moe-card-cover-image {
  height: 300px;
  overflow: hidden;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.moe-post-card .mdui-card-media .mdui-card-primary-title {
  color: #fff;
  text-decoration: none;
}

.moe-post-card .mdui-card-menu {
  z-index: 120;
}

/* ===== 文章二维码弹出框 ===== */
.moe-post-card .mdui-card-menu #QRcode {
  width: 180px;
  height: 180px;
  padding: unset;
  overflow-y: unset;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.moe-post-card .mdui-card-menu #QRcode img {
  width: auto;
  height: auto;
  border-radius: 3px;
}

.moe-post-card .moe-card-content {
  font-size: 16px;
  line-height: 25px;
  position: relative;
  padding: 10px 15px 10px 15px;
}

/* ===== 验证密码 ===== */
.moe-post-card .moe-card-content form.protected {
  width: 300px;
  margin: auto;
}

.moe-post-card .moe-card-content form.protected .mdui-textfield {
  width: calc(100% - 50px);
}

.moe-post-card .moe-card-content form.protected .mdui-btn {
  float: right;
  margin-top: -43px;
}

/* ===== P ===== */
.moe-post-card .moe-card-content p {
  margin: 0 0 1.2em 0;
}

/* ===== a 标签 ===== */
.moe-post-card .moe-card-content a:not([data-baguettebox]) {
  outline: none;
  overflow: hidden;
  position: relative;
  vertical-align: top;
  display: inline-block;
  text-decoration: none;
}

.moe-post-card .moe-card-content a:not([data-baguettebox]):before {
  left: 0;
  top: auto;
  bottom: 1px;
  width: 100%;
  height: 1px;
  content: " ";
  position: absolute;
  transition: all 0.2s;
  transform: scaleX(0);
  backface-visibility: hidden;
  -webkit-transition: all 0.2s;
  -webkit-transform: scaleX(0);
  -webkit-backface-visibility: hidden;
}

.moe-post-card .moe-card-content a:hover:before,
.moe-post-card .moe-card-content a:focus:before {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
}

/* ===== 引用 ===== */
.moe-post-card .moe-card-content blockquote {
  font-weight: 400;
  padding-left: 1em;
  margin: 1em 16px 1em 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-left: 4px solid rgba(0, 0, 0, 0.12);
}

.moe-post-card .moe-card-content blockquote footer {
  font-size: 86%;
  color: rgba(0, 0, 0, 0.54);
}

.moe-post-card .moe-card-content .moe-post-card-copy {
  font-size: 14px;
  line-height: 20px;
  padding: 5px 5px 5px 16px;
  border-radius: 1px 5px 5px 1px;
  background: rgba(66, 66, 66, 0.1);
  margin: 3.5em 3em 1em 2em !important;
}

.moe-post-card .moe-card-content .moe-post-card-copy a {
  word-break: break-all;
}

/* ===== 标题 ===== */
.moe-post-card .moe-card-content h1:before,
.moe-post-card .moe-card-content h2:before {
  content: "#";
  font-weight: bold;
  padding-right: 10px;
  transition: all 0.29s;
}

.moe-post-card .moe-card-content h3,
.moe-post-card .moe-card-content h4,
.moe-post-card .moe-card-content h5,
.moe-post-card .moe-card-content h6 {
  padding: 0 0 0 7px;
}

/* 分割线 */
.moe-post-card .moe-card-content h1:after,
.moe-post-card .moe-card-content h2:after {
  left: 0px;
  bottom: -8px;
  width: 100%;
  content: "";
  position: absolute;
  border-bottom: 1px solid #bdbdbd;
}

.moe-post-card .moe-card-content h1,
.moe-post-card .moe-card-content h2,
.moe-post-card .moe-card-content h3,
.moe-post-card .moe-card-content h4,
.moe-post-card .moe-card-content h5,
.moe-post-card .moe-card-content h6 {
  font-weight: 400;
  line-height: 1.35;
  position: relative;
  font-family: inherit;
  margin: 0.8em 0px 0.6em 0px;
}

.moe-post-card .moe-card-content h1 {
  font-size: 1.8em;
}

.moe-post-card .moe-card-content h2 {
  font-size: 1.6em;
}

.moe-post-card .moe-card-content h3 {
  font-size: 1.4em;
}

.moe-post-card .moe-card-content h4 {
  font-size: 1.2em;
}

.moe-post-card .moe-card-content h5 {
  font-size: 1.1em;
}

.moe-post-card .moe-card-content h6 {
  font-size: 1em;
}

.moe-post-card .moe-card-content h1 small,
.moe-post-card .moe-card-content h2 small,
.moe-post-card .moe-card-content h3 small,
.moe-post-card .moe-card-content h4 small,
.moe-post-card .moe-card-content h5 small,
.moe-post-card .moe-card-content h6 small {
  line-height: 1;
  font-size: 65%;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.54);
}

/* ===== 分割线 ===== */
.moe-post-card .moe-card-content hr {
  height: 10px;
  border: none;
  margin-bottom: 0.8em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/* ===== 无序/有序列表 ===== */
.moe-post-card .moe-card-content ul {
  list-style: disc;
  padding-left: 2em;
}

.moe-post-card .moe-card-content li ul {
  margin: 0.8em 0;
  list-style: circle;
}

.moe-post-card .moe-card-content ol {
  padding-left: 2em;
  list-style: decimal;
}

.moe-post-card .moe-card-content li ol {
  margin: 0.8em 0;
}

/* ===== 代码框 ===== */
.moe-post-card .moe-card-content code:not(.hljs) {
  color: #c7254e;
  padding: 2px 6px;
  border-radius: 3px;
  background-color: #f7f7f9;
  font-family: Roboto, Noto, Helvetica, Arial, sans-serif;
}

/* ===== 删除线 ===== */
.moe-post-card .moe-card-content del {
  text-decoration: line-through;
}

/* ===== Mark ===== */
.moe-post-card .moe-card-content mark {
  padding: 2px;
  margin: 0 5px;
  background: #fffdd1;
  border-bottom: 1px solid #ffedce;
}

/* ===== Ins/下划线 ===== */
.moe-post-card .moe-card-content ins,
.moe-post-card .moe-card-content u {
  text-decoration: none;
  border-bottom: 1px solid;
}

/* ===== Small ===== */
.moe-post-card .moe-card-content small {
  font-size: 80%;
}

/* ===== abbr ===== */
.moe-post-card .moe-card-content abbr[title] {
  cursor: help;
  text-decoration: none;
  border-bottom: 1px dotted;
}

/* ===== Kbd ===== */
.moe-post-card .moe-card-content kbd {
  color: #fff;
  font-size: 90%;
  padding: 2px 6px;
  border-radius: 2px;
  font-family: initial;
  background-color: #333;
}

/* ===== img ===== */
.moe-post-card .moe-card-content img.lazyload {
  max-width: 100%;
  margin: 10px auto;
  border-radius: 5px;
  display: block !important;
}

/* 懒加载 */
img.lazyload {
  opacity: 0;
  display: block;
}

img.loaded {
  opacity: 1;
}

img:not(.initial) {
  transition: opacity 1s;
}

/* 表格 */
.mdui-table-fluid {
  border-radius: 3px;
}

/* ===== 短代码 ===== */
.moe-short-code-hidden {
  color: #424242;
  padding: 1px;
  cursor: default;
  border-radius: 3px;
  transition: all 0.3s;
  background-color: #424242;
}

.moe-short-code-hidden:hover {
  background-color: transparent;
}

.moe-short-code-text-center {
  display: block;
  text-align: center;
}

/* ========== 文章导航栏 ========== */
.moe-post-nav {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.5);
}

.moe-post-nav a {
  color: #fff !important;
}

.moe-post-nav .moe-post-nav-left,
.moe-post-nav .moe-post-nav-right {
  height: 96px;
}

.moe-post-nav .moe-post-nav-left {
  text-align: left;
}

.moe-post-nav .moe-post-nav-right {
  text-align: right;
}

.moe-post-nav .moe-post-nav-direction {
  opacity: 0.55;
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 1px;
}

.moe-post-nav .moe-post-nav-chapter {
  margin-left: 34px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.moe-post-nav .moe-post-nav-left .mdui-icon {
  float: left;
  margin-right: 10px;
}

.moe-post-nav .moe-post-nav-right .mdui-icon {
  float: right;
  margin-left: 10px;
}

.moe-post-nav .moe-post-nav-text .mdui-icon {
  width: 24px;
  padding-top: 18px;
}

.moe-post-nav .moe-post-nav-chapter a {
  color: #fff !important;
  text-decoration: none;
}

.moe-post-nav .moe-post-nav-text {
  width: 100%;
  height: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-top: 24px;
  display: inline-block;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

/* ========== 评论 ========== */
.moe-comment-card {
  width: 900px;
  margin: 0px auto 20px auto;
}

.moe-comment-card .moe-comment-card-header {
  padding: 25px 15px 0px 15px;
}

.moe-comment-card .moe-comment-card-header .moe-comment-card-title {
  font-size: 22px;
  font-weight: bolder;
}

.moe-comment-card .moe-comment-card-header .moe-comment-card-small-title {
  font-size: 16px;
  margin-top: 10px;
}

.moe-comment-card .moe-comment-card-header:after {
  width: 100%;
  height: 1px;
  content: "";
  display: block;
  margin-top: 25px;
  background-color: rgba(0, 0, 0, 0.1);
}

.moe-comment-card .moe-comment-card-content {
  padding: 0px 15px 20px 15px;
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-username {
  width: 48%;
  float: left;
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-email {
  width: 48%;
  float: right;
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-url {
  width: 100%;
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-text {
  width: calc(100% - 50px);
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-url {
  opacity: 1;
  height: 54px;
  transition: all 0.15s;
}

.moe-comment-card .moe-comment-card-content .moe-comment-input-url-hidden {
  opacity: 0;
  height: 0px;
  padding-bottom: 0px;
}

.moe-comment-card .moe-comment-card-footer {
  overflow: hidden;
  padding: 0px 15px 20px 15px;
}

.moe-comment-card .moe-comment-card-footer .moe-comment-card-btns {
  float: right;
}

.moe-comment-card
  .moe-comment-card-footer
  .moe-comment-card-btns
  #showLinkInput {
  margin-right: 10px;
}

/* ===== 评论关闭 ===== */
.moe-comment-card .moe-comment-card-close {
  font-size: 18px;
  padding: 20px 10px;
}

.moe-comment-card .moe-comment-card-close i {
  font-size: 28px;
  vertical-align: middle;
}

/* ===== 表情按钮 ===== */
.moe-comment-card
  .moe-comment-card-content
  .moe-comment-textarea-and-owoBtn-main {
  position: relative;
}

.moe-comment-card .moe-comment-card-content .moe-comment-owo-btn {
  right: 5px;
  bottom: 28px;
  padding: 3px;
  position: absolute;
  border-radius: 3px;
  transition: all 0.2s;
  color: rgba(0, 0, 0, 0.54);
  -webkit-transition: all 0.2s;
}

/* ===== 已登录者 Header ===== */
.moe-comment-card .moe-comment-card-content .mdui-card-header {
  margin: 0px -10px 0px -15px;
}

/* ===== 未登录者头像 ===== */
.moe-comment-card .moe-comment-card-content #moe-comment-author-avatar img,
.moe-comment-card
  .moe-comment-card-content
  #moe-comment-author-avatar
  .mdui-spinner {
  width: 24px;
  height: 24px;
}

.moe-comment-card .moe-comment-card-content #moe-comment-author-avatar img {
  border-radius: 50%;
}

/* ========= 评论区 ==========*/
.moe-comments-list-card {
  width: 900px;
  overflow: visible;
  margin: 0px auto 20px auto;
}

.moe-comments-list-card .moe-comments-list-header {
  padding: 25px 15px 25px 15px;
}

.moe-comments-list-card .moe-comments-list-header:after {
  left: 0px;
  width: 100%;
  height: 1px;
  content: "";
  margin-top: 25px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
}

.moe-comments-list-card .moe-comments-list-header .moe-comments-list-title {
  font-size: 22px;
  font-weight: bolder;
}

.moe-comments-list-card .moe-comments-list-header .moe-comments-list-number {
  font-size: 16px;
  margin-top: 10px;
}

.moe-comments-list-card
  .moe-comments-list-header
  .moe-comments-list-number
  span {
  font-weight: bolder;
}

/* 评论列表框 */
.moe-comments-list-box .moe-comments-box {
  position: relative;
}

.moe-comments-list-box .moe-comments-box:after {
  width: 100%;
  height: 1px;
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.15);
}

.moe-comments-list-box .moe-comments-content-wrap {
  display: flex;
  list-style: none;
  position: relative;
  align-items: center;
  padding: 10px 16px 20px 16px;
}

.moe-comments-list-box .moe-comments-content-wrap .moe-comments-content {
  margin-left: 10px;
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-author {
  font-size: 15px;
  font-weight: 500;
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-time {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-time
  span {
  font-weight: bolder;
  color: rgba(239, 83, 80, 0.89);
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-reply {
  top: 16px;
  right: 16px;
  position: absolute;
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-reply
  a {
  text-decoration: none;
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-reply
  a
  button {
  min-width: 20px !important;
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-textBox {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  line-height: 1.3;
  word-break: break-all;
  word-wrap: break-word;
  color: rgba(0, 0, 0, 0.85);
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-content
  .moe-comments-textBox
  .moe-comment-reply-at {
  text-decoration: none;
  vertical-align: middle;
}

.moe-comments-box.moe-post-card {
  width: unset !important;
  margin: 0 !important;
  background-color: inherit !important;
}

.moe-comments-box.moe-post-card .moe-comments-textBox.moe-card-content {
  padding: 0 !important;
}

/* ===== 头像框 ===== */
.moe-comments-list-box .moe-comments-content-wrap .moe-comments-avatar-box {
  position: relative;
  transition: all 0.3s;
}

.moe-comments-list-box
  .moe-comments-content-wrap:hover
  .moe-comments-avatar-box {
  transform: scale(1.12333);
  -o-transform: scale(1.12333);
  -ms-transform: scale(1.12333);
  -moz-transform: scale(1.12333);
  -webkit-transform: scale(1.12333);
}

.moe-comments-list-box
  .moe-comments-content-wrap
  .moe-comments-avatar-box
  .moe-comments-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.moe-comments-list-box .moe-comments-content-wrap .moe-comment-by-owner::after {
  right: 0;
  bottom: 3px;
  width: 16px;
  height: 16px;
  content: "";
  display: block;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAUDBAQEAwUEBAQFBQUGBwwIBwcHBw8LCwkMEQ8SEhEPERETFhwXExQaFRERGCEYGh0dHx8fExciJCIeJBweHx7/2wBDAQUFBQcGBw4ICA4eFBEUHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh4eHh7/wAARCAAZABkDABEAAREBAhEB/8QAFwABAQEBAAAAAAAAAAAAAAAABgAFCP/EACMQAAECBgMAAwEAAAAAAAAAAAECAwAEBQYREiExUUFygZH/xAAXAQEBAQEAAAAAAAAAAAAAAAAFAAYH/8QAKxEAAQMCAwcEAwEAAAAAAAAAAQACBQQRAxIxEyFBYYGRwSRxodEUMlHw/9oADAMAAAERAhEAPwDrK5q43SWkoQgOTLgylJ6A9MCzEw2PaGtF3nQeSko+PNWSSbNCPMVu4nZZ+oB1tMszjbZsBJJIGBxknn2M1hy8piYb6gOGRvIW9huv8ph8fQse3BIOY8/lIbarjdWaUhaQ3MtjKkjoj0RpYeYbINLXCzxqPIQ8hHupCCDdpWzDSNQC7A0brUJ1TiZfCOUDJ11+P3Mc8nAwyh25IZu01tbh1WtjC4UQ2X7b+90hxR6jbnbktT2lfXr+55P6Y0foKyP4twm9NO/2SiPVU9Xwc89df92R60y0brQZJLiZfC+FnKtdfn9xGcgywyg2AOTfrra3Hql5PN+EdrbNu0/t/pP46Gsksa5qG3VmkrQsNzLYwlRHBHhgSYh2yDQ5ps8aHwUlHyBpCQRdpR1+iXE7KsU8stplmSddXAEkkk5POT35GbxIiVxMNlMWjI3mLe533+EwyvoWPdjAnMeR7JFbNDbpLSlrWHJlwYUoDgDwRpIeHbHtLnG7zqfAQ8hIGrIAFmhbMNo1USlRKVEpUSl//9k=");
}

/* ===== 回复框 ===== */
.moe-comments-list-card .moe-comment-card {
  margin-top: 0px;
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.moe-comments-list-box .moe-comment-card {
  left: 0;
  top: 100%;
  width: 100%;
  z-index: 10;
  border-top: none;
  position: absolute;
  border-radius: 5px !important;
  box-shadow: 0 6px 6px -3px rgba(0, 0, 0, 0.2),
    0 10px 14px 1px rgba(0, 0, 0, 0.14), 0 4px 18px 3px rgba(0, 0, 0, 0.12);
}

/* ===== 子评论 ===== */
.moe-comments-box .moe-comment-children {
  margin-left: 30px;
}

.moe-comments-box .moe-comment-children .moe-comment-children {
  margin-left: 0px;
}

.moe-comment-children .moe-comments-box:after {
  height: 0px;
  content: "";
  display: none;
}

/* 评论分页 */

.moe-comments-list-footer .moe-comments-page-navigator {
  padding: 10px 0;
  list-style: none;
  text-align: center;
}

.moe-comments-list-footer .moe-comments-page-navigator li {
  display: inline-block;
}

.moe-comments-list-footer .moe-comments-page-navigator li a {
  width: 30px;
  height: 30px;
  padding: 3px;
  color: #000;
  margin: 5px 3px;
  line-height: 33px;
  border-radius: 50px;
  display: inline-block;
  text-decoration: none;
  transition: all 0.3s;
}

.moe-comments-list-footer .moe-comments-page-navigator li a:hover {
  color: #fff;
  background-color: rgba(66, 66, 66, 0.55);
}

.moe-comments-list-footer .moe-comments-page-navigator .current a {
  color: #fff;
  cursor: not-allowed;
  background-color: #424242;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.55);
}

.moe-comments-list-footer .moe-comments-page-navigator .current a:hover {
  background-color: #424242;
}

/* ========== 404 页面 ========== */
.moe-404-box {
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.moe-404-card {
  width: 900px;
  margin: auto;
}

.moe-404-card .mdui-card-media .moe-card-cover-image {
  height: 300px;
  overflow: hidden;
  transition: all 0.3s;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.moe-404-card form#search-404-form .mdui-textfield {
  width: calc(100% - 50px);
}

.moe-404-card form#search-404-form .mdui-btn {
  float: right;
  margin-top: -43px;
}

.moe-404-card .moe-404-btn {
  margin-top: -20px;
}

/* ========== 表情框 ========== */
#owoBox {
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  width: 600px;
  margin: auto;
  z-index: 6100;
  position: fixed;
  border-radius: 5px;
  transition: all 0.3s;
  background-color: #fff;
}

#owoBox.moe-owo-open {
  opacity: 1;
  transform: translateY(-36px);
}

#owoBox header.moe-owo-header {
  position: relative;
  padding: 10px 10px;
  border-bottom: 1px solid #eee;
}

#owoBox header.moe-owo-header .moe-owo-header-title {
  padding: 3px;
  font-size: 20px;
  display: inline-block;
}

#owoBox header.moe-owo-header button {
  float: right;
}

#owoBox main.moe-owo-main {
  padding: 0px;
  height: 210px;
  font-size: 15px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

#owoBox main.moe-owo-main a.moe-owo-text-btn {
  padding: 3px;
  border-radius: 5px;
  margin: 5px 3px 5px 3px;
}

#owoBox main.moe-owo-main a.moe-owo-emoji-btn,
#owoBox main.moe-owo-main a.moe-owo-smallPicture-btn {
  width: 40px;
  height: 30px;
  padding: 3px;
  min-width: 20px;
  min-height: 20px;
  line-height: 28px;
  border-radius: 5px;
  margin: 5px 3px 5px 3px;
}

#owoBox main.moe-owo-main a.moe-owo-smallPicture-btn {
  width: 40px;
  height: 36px;
  line-height: 0;
}

#owoBox main.moe-owo-main a.moe-owo-smallPicture-btn img {
  width: 30px;
  height: 30px;
}

#owoBox main.moe-owo-main a.moe-owo-picture-btn {
  margin: 3px;
  padding: 3px;
  height: auto;
  line-height: 0;
  min-width: auto;
  border-radius: 5px;
}

#owoBox main.moe-owo-main a.moe-owo-picture-btn img {
  max-height: 52px;
  border-radius: 3px;
}

#owoBox footer.moe-owo-footer-tab {
  border-top: 1px solid #e0e0e0;
}

/* ===== 表情框遮罩层 ===== */
#moe-owo-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 6090;
  position: fixed;
  transition: all 0.2s;
  background-color: rgba(0, 0, 0, 0.3);
}

#moe-owo-overlay.moe-owo-overlay-open {
  opacity: 1;
}

/* ===== 全局表情 ===== */
.moe-owo-picture {
  max-height: 52px;
  border-radius: 5px;
  vertical-align: middle;
}

.moe-owo-smallPicture {
  height: 30px;
  box-shadow: none;
  display: inline-block;
  vertical-align: middle;
}

/* ==================== 独立页面 ==================== */
/* ========== 归档页面 ========== */
.moe-archive-card {
  width: 900px;
  margin: auto;
}

.moe-archive-tags-card {
  margin: 38px auto 20px auto;
}

.moe-archive-tags-card header.moe-archive-tags-header,
.moe-archives-list-card header.moe-archives-list-card-header {
  padding: 15px 15px;
}

.moe-archive-tags-card header.moe-archive-tags-header .moe-archive-tags-title,
.moe-archives-list-card
  header.moe-archives-list-card-header
  .moe-archives-list-title {
  font-size: 23px;
  line-height: 40px;
}

.moe-archive-tags-card header.moe-archive-tags-header .moe-archive-tags-count {
  font-size: 14px;
}

.moe-archive-tags-card .moe-archive-tags-content {
  padding: 10px 15px 30px 15px;
}

.moe-archive-tags-card .moe-archive-tags-content .moe-archive-tag-not {
  font-size: 14px;
}

.moe-archive-tags-card .moe-archive-tags-content a.moe-archive-tags-link {
  padding: 8px;
  font-size: 16px;
  margin: 3px 1px;
  border-radius: 3px;
  text-decoration: none;
  display: inline-block;
  transition: all 0.3s;
  color: rgba(0, 0, 0, 0.55);
}

.moe-archives-list-card {
  margin: 0px auto 20px auto;
}

.moe-archives-list-card .moe-archives-list-content .mdui-panel-item {
  background-color: initial;
}

.moe-archives-list-card
  .moe-archives-list-content
  .mdui-panel-item
  .mdui-panel-item-title {
  font-size: 23px;
}

.moe-archives-list-card
  .moe-archives-list-content
  .mdui-panel-item
  .mdui-panel-item-body
  .moe-archives-list-card
  .moe-archives-list-content
  .mdui-panel-item
  .mdui-panel-item-body:before {
  top: 0;
  left: 18px;
  width: 2px;
  height: 100%;
  content: "";
  position: absolute;
  background: #000000;
}

.moe-archives-list-card .moe-archives-list-content .moe-archives-list-alist {
  color: initial;
  display: block;
  padding: 15px 10px;
  font-size: 14px;
  transition: all 0.3s;
  text-decoration: none;
}

.moe-archives-list-card
  .moe-archives-list-content
  .moe-archives-list-alist:hover {
  background-color: rgba(0, 0, 0, 0.15);
}

.moe-archives-list-card
  .moe-archives-list-content
  .moe-archives-list-alist
  span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.55);
}

/* ========== 友链页面 ========== */
.moe-links-box {
  width: 80%;
  margin: 10px auto 30px;
}

.moe-links-box .moe-links-href {
  cursor: pointer;
  text-decoration: none;
}

.moe-links-box .moe-links-card {
  margin-top: 15px;
  border-radius: 6px;
}

.moe-links-box .moe-links-card .mdui-card-media main {
  width: 100%;
  height: 80px;
  filter: blur(6px);
  background-size: cover;
  -webkit-filter: blur(6px);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.moe-links-box .moe-links-card .moe-links-avatar {
  width: 60px;
  height: 60px;
  margin-top: -42px;
  border-radius: 50%;
}

.moe-links-box .moe-links-card .moe-links-title {
  display: block;
  font-size: 23px;
  margin-bottom: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.moe-links-box .moe-links-card .moe-links-description {
  display: block;
  font-size: 15px;
  min-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* ===== 友链申请框 ===== */
#links-dialog {
  width: 900px;
  overflow: auto;
  border-radius: unset;
  height: 100vh !important;
  max-width: 800px !important;
  max-height: 100vh !important;
}

#links-dialog .mdui-card {
  width: 100%;
  box-shadow: none;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

#links-dialog .moe-post-card,
#links-dialog .moe-comment-card,
#links-dialog .moe-comments-list-card {
  margin: 0px auto;
}

#links-dialog #links-close {
  top: 20px;
  right: 20px;
  z-index: 6080;
  position: absolute;
  transition: all 0.3s;
}

#links-dialog .moe-comments-list-footer {
  margin: 20px 0px;
}

/* ===== 追番页面(未启用配套插件时) ===== */
.moe-bangumi-page-card .bangumi-plugin-disable {
  padding: 20px;
}

.moe-bangumi-page-card .bangumi-plugin-disable span {
  color: #ff5252;
  display: block;
  font-size: 23px;
  font-weight: bolder;
  text-align: center;
}

/* ===== 代码高亮 ===== */
.hljs {
  overflow: auto;
  max-height: 300px;
  border-radius: 5px;
  -webkit-overflow-scrolling: touch;
}

.hljs ol {
  padding: 0px;
  list-style: decimal;
  padding-left: unset !important;
  margin: 0px 0px 0 40px !important;
}

.hljs ol li {
  white-space: pre;
  margin: 0 !important;
  padding: 5px !important;
  list-style: decimal-leading-zero;
  border-left: 1px solid #ddd !important;
}

code {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

/* ========== 回到顶部按钮 ========== */
#go-top {
  z-index: 990;
}

/* ========== Footer ========== */
#footer {
  clear: both;
  width: 100%;
  font-size: 16px;
  background-color: #424242;
  color: rgba(255, 255, 255, 0.85);
}

#footer a {
  color: #939090;
  transition: all 0.3s;
  text-decoration: none;
}

#footer a:hover {
  color: #eeeeee;
}

#footer .moe-footer-box {
  width: 900px;
  margin: auto;
  padding: 23px 8px 36px 8px;
}

#footer .moe-footer-top:after {
  height: 1px;
  width: 100%;
  content: "";
  display: block;
  margin: 12px 0px 10px 0px;
  background-color: rgba(255, 255, 255, 0.35);
}

#footer .moe-footer-bottom .moe-footer-theme-or-powered {
  float: left;
}

#footer .moe-footer-bottom .moe-footer-icp {
  float: right;
}

/* ========== 图片懒加载 ========== */
.castle-lazyload {
  opacity: 0;
  transition: all 0.3s;
}

.castle-lazyload.display {
  opacity: 1!important;
}

/* ========== 媒体查询 ========== */
@media (max-width: 1170px) {
  .mdui-drawer-body-right .moe-post-card,
  .mdui-drawer-body-right .moe-comment-card,
  .mdui-drawer-body-right .moe-comments-list-card {
    width: 96%;
  }
}

@media (min-width: 1024px) {
  #toc-sidebar {
    width: 240px;
    transform: translateX(250px);
  }

  #toc-Btn {
    bottom: 104px;
  }

  .mdui-drawer-body-right #go-top,
  .mdui-drawer-body-right #toc-Btn {
    right: 264px;
  }

  #moe-toc-sidebar-overlay.moe-toc-sidebar-overlay-open {
    display: none !important;
  }

  .mdui-drawer-body-right.mdui-locked {
    overflow: unset;
  }
}

@media (max-width: 1023px) {
  #moe-post-list .mdui-card,
  .moe-post-card,
  #moe-post-list .moe-pagination,
  .moe-comment-card,
  .moe-comments-list-card,
  .moe-404-card,
  .moe-archive-card,
  .mdui-drawer-body-right .moe-post-card,
  .mdui-drawer-body-right .moe-comment-card,
  .mdui-drawer-body-right .moe-comments-list-card,
  #footer .moe-footer-box {
    width: 800px;
  }
}

@media (max-width: 970px) {
  #footer .moe-footer-box {
    text-align: center;
  }
}

@media (max-width: 959px) and (orientation: landscape) {
  #moe-pjax-content {
    padding: 48px 0px 0px 0px;
  }
}

@media (max-width: 830px) {
  #footer .moe-footer-box {
    width: 94%;
  }
}

@media (max-width: 810px) {
  #moe-post-list .mdui-card,
  .moe-post-card,
  .moe-comment-card,
  .moe-comments-list-card,
  .moe-404-card,
  .moe-archive-card,
  .mdui-drawer-body-right .moe-post-card,
  .mdui-drawer-body-right .moe-comment-card,
  .mdui-drawer-body-right .moe-comments-list-card {
    width: 96%;
  }

  #moe-post-list .moe-pagination {
    width: 92%;
  }

  .moe-post-card {
    margin-top: 0px;
  }

  .moe-post-card .moe-card-content .moe-post-card-copy {
    margin: 3.5em 0em 1em 0em !important;
  }
}

@media (max-width: 800px) {
  #links-dialog {
    width: 100%;
  }
}

@media (max-width: 610px) {
  #search-dialog .moe-search-content form,
  #search-dialog .moe-search-content .moe-search-result {
    width: 92%;
  }

  #owoBox {
    width: 95%;
  }

  #moe-post-list .moe-nopic-card {
    padding: 15px;
  }

  #moe-post-list .moe-nopic-card .moe-nopic-title {
    font-size: 17px;
    margin-top: 8px !important;
  }

  #moe-post-list .moe-nopic-card .moe-nopic-info {
    margin: 10px 0px -8px 0px;
  }
}

@media (max-width: 600px) {
  .mdui-drawer-body-right .moe-post-card,
  .mdui-drawer-body-right .moe-comment-card,
  .mdui-drawer-body-right .moe-comments-list-card {
    width: 100%;
  }

  #moe-pjax-content {
    padding: 56px 0px 0px 0px;
  }

  #search-dialog .moe-search-content form:after {
    margin-bottom: 0px;
  }

  #search-dialog .moe-search-content form i {
    font-size: 26px;
  }

  #search-dialog .moe-search-content form #searchInput {
    font-size: 18px;
  }

  #moe-post-list .moe-default-card .mdui-card-media .moe-card-cover-image,
  .moe-post-card .mdui-card-media .moe-card-cover-image {
    height: 260px;
  }

  .moe-post-card {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    margin: -56px auto 0px auto;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  .moe-post-nav {
    margin-top: 0px;
  }

  .moe-comment-card {
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }

  .moe-comments-list-card,
  .moe-comment-card {
    width: 100%;
    margin: 0px auto;
    box-shadow: none;
    border-radius: 0px;
    margin-bottom: 0px;
  }

  .moe-comment-card .moe-comment-card-content .moe-comment-input-username,
  .moe-comment-card .moe-comment-card-content .moe-comment-input-email {
    width: 100%;
    float: none;
  }

  .moe-archive-card.moe-archive-tags-card {
    margin: 18px auto 20px auto;
  }
}

@media (max-width: 530px) {
  .moe-login {
    width: 100%;
    height: 100vh !important;
    max-width: 100% !important;
    max-height: 100vh !important;
  }

  .moe-login .moe-login-header {
    height: 240px;
  }

  .moe-login .moe-login-content .mdui-checkbox {
    left: 0px;
    bottom: 0px;
    margin-top: 50px;
    position: relative;
  }

  .moe-login .moe-login-content .moe-login-submit {
    right: 0px;
    bottom: 0px;
    float: right;
    margin-top: 50px;
    position: relative;
  }
}

@media (max-width: 570px) {
  #footer .moe-footer-box {
    padding: 23px 8px 15px 8px;
  }

  #footer .moe-footer-bottom .moe-footer-icp {
    margin-bottom: 5px;
  }

  #footer .moe-footer-bottom .moe-footer-icp,
  #footer .moe-footer-bottom .moe-footer-theme-or-powered {
    float: none;
  }
}

@media (max-width: 370px) {
  .moe-post-card .moe-card-content form.protected {
    width: 95%;
  }
}

/* ========== 滚动条 ========== */
html::-webkit-scrollbar-track-piece {
  background: rgba(0, 0, 0, 0);
}

html::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

html::-webkit-scrollbar-thumb {
  height: 40px;
  border-radius: 4px;
  background-color: rgba(165, 165, 165, 1);
}

html::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
